<template>
  <b-card
    title="标签列表"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            label-size="lg"
            label="标签名称"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchTagName"
              placeholder="请输入标签名称"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" offset-md="3" class="tRight">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="pageTag(true)"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        v-b-modal.modal-prevent-closing
        v-if="$checkButtonPermission('201001001')"
      >
        添加
      </b-button>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="removeTags"
        v-if="$checkButtonPermission('201001002')"
      >
        删除标签
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      ref="tagTable"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageSize
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
      @on-cell-click="onCellClick"
      @on-select-all="onSelectAll"
      @on-row-dblclick="onRowDoubleClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- 查看 -->
            <b-dropdown-item
              :to="{ name: 'label-configInfo', params: { id: props.row.id , edit: false} }"
            >
              <span>
                <feather-icon icon="VoicemailIcon"/>
                <span class="align-middle ml-50">查看</span>
              </span>
            </b-dropdown-item>
            <!-- 编辑 -->
            <b-dropdown-item
              :to="{ name: 'label-configInfo', params: { id: props.row.id , edit:true } }"
            >
              <span>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="tCenter">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </template>
    </vue-good-table>
    <template>
      <b-modal
        id="modal-prevent-closing"
        ref="tag-modal"
        title="创建标签"
        @show="resetTagCreateModal"
        @hidden="resetTagCreateModal"
        ok-title="确定"
        cancel-title="返回"
        @ok="handleTagCreateOk"
      >
        <form ref="createTagform" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="标签名"
            invalid-feedback="必须填写标签名称且低于6个字符"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="createTagForm.name"
              :state="nameState"
              required
              placeholder="请输入标签名"
            ></b-form-input>
          </b-form-group>

<!--          <b-form-group
            label="Note"
          >
            <b-form-textarea
              id="textarea"
              v-model="createTagForm.note"
              placeholder=""
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>-->
        </form>
      </b-modal>
    </template>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput,BFormTextarea, BFormSelect, BRow, BCol,BCard,BButton,BModal,BDropdown,BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,VueGoodTable,BAvatar,BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BButton,
    vSelect,BModal,BDropdown,BDropdownItem,BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      nameState: null,
      createTagForm:{
        name: "",
        note: "",
      },
      currentPage: 1,
      searchTagName:"",
      pageSize: 5,
      total: 0,
      dir: false,
      roleOptions: [
        { label:'在线', value: "1" },
        { label: '离线', value: "2" },
        { label: '未激活', value: "3" },
      ],
      roleFilter:null,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: '标签名称',
          field: 'name',
        },
        {
          label: '机构名称',
          field: 'tenantName',
        },
        {
          label: '创建人员',
          field: 'createUName',
        },
        {
          label: '创建时间',
          field: 'createTime',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.pageTag()
  },
  methods: {
      checkCreateTagFormValidity() {

        let valid = this.$refs.createTagform.checkValidity()
        if(valid){
          if(this.createTagForm.name && this.createTagForm.name.length > 6){
            this.nameState = false
            valid = false
          }
        }
        this.nameState = valid
        return valid
      },
      handleSubmit() {
        if (!this.checkCreateTagFormValidity()) {
          return
        }
        request.post('tob/tag/bTag/save', this.createTagForm).then(res => {
          console.log('RES',res)
          if (res.data.success) {
              this.$bvToast.toast("操作成功",{
                title: `提示`,
                variant: 'success',
                solid: true
              })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
            this.pageTag()
          }
      })
    },
    handleTagCreateOk(bvModalEvent){
        bvModalEvent.preventDefault()
        // 创建
        console.log('handleTagCreateOk')
        this.handleSubmit()
    },
    resetTagCreateModal() {
      this.createTagForm.name = ''
      this.createTagForm.note = ''
    },
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params){
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      console.log('params',params)
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageTag()
    },
    sureSubmit(){
      console.log('ok')
    },
    pageTag(resetCurrentPage){
      if(resetCurrentPage)
        this.currentPage = 1
      request.get('tob/tag/bTag/page', {tagName:this.searchTagName,pageSize: this.pageSize,currentPage: this.currentPage}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('total',this.total)
        }
      })
    },
    removeTags(){
      let tagList = this.$refs['tagTable'].selectedRows
      if(tagList.length <= 0){
        this.$bvToast.toast("请选择要删除的标签",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      console.log('tagList',tagList)
      let tagIdList = []
      for(let i=0;i<tagList.length;i++){
        tagIdList.push(tagList[i].id)
      }
      request.delete('tob/tag/bTag/delete', {idList: tagIdList}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
          this.pageTag()
        }
      })
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
